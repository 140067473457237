import React from "react";
import "./App.css";

function App() {
  return (
    <div className="container">
      <header className="header">
        <h1>Period or Not</h1>
        <p>Simple (and fun) way to track period</p>
      </header>
      <span className="badge">Soon</span>
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
          background-color: #1a202c;
          color: #e2e8f0;
        }

        .header {
          margin-bottom: 1rem;
          text-align: center;
        }

        .header h1 {
          font-size: 2.25rem;
          font-weight: 700;
          color: #e2e8f0;
        }

        .header p {
          font-size: 1.125rem;
          color: #a0aec0;
          margin-top: 0.5rem;
        }

        .badge {
          display: inline-block;
          background-color: #f56565;
          color: #ffffff;
          font-size: 0.75rem;
          font-weight: 600;
          padding: 0.25rem 0.5rem;
          border-radius: 9999px;
        }
      `}</style>
    </div>
  );
}

export default App;
